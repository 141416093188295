<template>
  <Breadcrumbs main="Banner" title="Edit Banner" />
  <LoadingModal :show="isLoadingModal" />
  <ModalListArticle :isVisible="isModalVisible" modalId="selectArticle" title="Modal List Article"
    @update:isVisible="isModalVisible = $event" />
  <ModalListPromotion :isVisible="isModalPromotionVisible" modalId="selectPromotion" title="Modal List Promotion"
    @update:isVisible="isModalPromotionVisible = $event" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nama</label>
                <div class="col-sm-9">
                  <input v-model="BannerName" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Image</label>
                <div class="col-sm-9">
                  <img :src="localImage" class="img-thumbnail mb-2" />
                  <input class="form-control" @change="cek" accept="image/*" id="upcover" type="file" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Deskripsi</label>
                <div class="col-sm-9">
                  <input v-model="description" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Prioritas</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="Priority">
                    <option value="">Select Option</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Start Date</label>
                <div class="col-sm-9">
                  <input v-model="startDate" class="form-control" type="date" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">End Date</label>
                <div class="col-sm-9">
                  <input v-model="endDate" class="form-control" type="date" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Action Name</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="actionName">
                    <option value="">Select Option</option>
                    <option value="onclick">On Click</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4 g-3" v-show="actionName == 'onclick'">
                <label class="col-sm-3 col-form-label text-start">Action Target</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="actionTarget" @change="selectActionLink()">
                    <option value="">Select Option</option>
                    <option value="webview">Webview</option>
                    <option value="article">Article</option>
                    <option value="promotion">Promotion</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4 g-3" v-show="actionTarget != ''">
                <label class="col-sm-3 col-form-label text-start">Action Link</label>
                <div class="col-sm-9">
                  <div v-if="actionTarget == 'webview'">
                    <input v-model="actionLink" class="form-control" type="text" />
                  </div>
                  <div style="display: flex; align-items: center;" v-else-if="actionTarget == 'article'">
                    <input v-model="actionLink" class="form-control" type="text" hidden />
                    <input v-model="judulArticle" class="form-control" type="text" readonly />
                    <button type="button" class="btn btn-xs btn-outline-success" @click="selectArticle()"
                      style="margin-left: 8px;">
                      Select
                    </button>
                  </div>
                  <div style="display: flex; align-items: center;" v-else-if="actionTarget == 'promotion'">
                    <input v-model="actionLink" class="form-control" type="text" hidden />
                    <input v-model="promotionName" class="form-control" type="text" readonly />
                    <button type="button" class="btn btn-xs btn-outline-success" @click="selectPromotion()"
                      style="margin-left: 8px;">
                      Select
                    </button>
                  </div>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Tag</label>
                <div class="col-sm-9">
                  <div class="tag-input">
                    <div v-for="(tag, index) in bannerTags" class="tag-input__tag" :key="index">
                      {{ tag }}
                      <span @click="removeTag(index)">x</span>
                    </div>
                    <input
                      type="text"
                      list="listArticle"
                      class="tag-input__text"
                      @keydown.enter="addTag"
                      @keydown.188="addTag"
                      @keydown.delete="removeLastTag"
                    />
                    <datalist id="listArticle">
                      <option v-for="article in dataTag" :key="article" :value="article" />
                    </datalist>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Active</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="selectActive" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Public</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="selectPublic" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="text-end">
                    <button @click="addItem" class="btn btn-outline-success btn-sm me-4" type="button">Simpan</button>
                    <router-link to="/banner/list">
                      <button class="btn btn-outline-primary btn-sm" type="button">Batal</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import DropZone from "dropzone-vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalListArticle from "./modal_list_article.vue";
import ModalListPromotion from "./modal_list_promotion.vue";
import EventBus from '@/eventBus.js';
import LoadingModal from '@/components/modal_loading.vue';

export default {
  components: {
    VueFeather,
    DropZone,
    ModalListArticle,
    LoadingModal,
    ModalListPromotion,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      user: [],
      meta: [],
      BannerName: "",
      description: "",
      bannerTags: [],
      startDate: "",
      endDate: "",
      selectActive: false,
      Priority: "",
      selectNeedLogin: false,
      selectPublic: false,
      editor: ClassicEditor,
      content: "",
      BingkaiGambar: "#ffffff",
      localImage: "",
      imagechange: false,
      dataTag: [],
      actionName: "",
      actionTarget: "",
      actionLink: "",
      listActionLink: [],
      isLoadingModal: false,
      isModalVisible: false,
      isModalPromotionVisible: false,
      judulArticle: "",
      promotionName: ""
    };
  },
  async created() {
    EventBus.on('selectArticle', this.setArticle);
    EventBus.on('selectPromotion', this.setPromotion);
    this.actionName = this.$store.state.dataBanner.actionName;
    this.actionTarget = this.$store.state.dataBanner.actionTarget ? this.$store.state.dataBanner.actionTarget : '';
    this.actionLink = this.$store.state.dataBanner.actionLink;
    if (this.$store.state.dataBanner.actionTarget == "article") {
      axios
        .get("/admin-api/article/list-articles?articleId="+this.$store.state.dataBanner.actionLink, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.judulArticle = x.data.data.articles[0].title;
        });
    } else if (this.$store.state.dataBanner.actionTarget == "promotion") {
      axios
        .get("/admin-api/promotion/list-promotions?promotionId=" + this.$store.state.dataBanner.actionLink, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.promotionName = x.data.data.promotions[0].name;
        });
    }
  },
  mounted() {
    this.user = localStorage.getItem("token");
    this.getdata();
    this.getTag();
  },
  methods: {
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    async getdata() {
      this.BannerName = this.$store.state.dataBanner.name;
      this.bannerTags = this.$store.state.dataBanner.bannerTags;
      let res = this.bannerTags.map((c) => {
        return Object.values(c);
      });
      res = res.flat();
      res = [...new Set(res)];
      this.bannerTags = res;

      this.content = this.$store.state.dataBanner.content;
      this.description = this.$store.state.dataBanner.description;
      this.selectActive = this.$store.state.dataBanner.isActive;
      this.selectPublic = this.$store.state.dataBanner.isPublic;
      this.startDate = this.$store.state.dataBanner.startDate;
      this.endDate = this.$store.state.dataBanner.endDate;
      this.Priority = this.$store.state.dataBanner.priority;
      this.localImage = this.$store.state.dataBanner.image;
    },

    cek() {
      this.imagechange = true;
    },

    getTag() {
      axios
        .get("/admin-api/banner/list-banner-tags", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let data = x.data.data.bannerTags;
          let res = data.map((c) => {
            return Object.values(c);
          });
          res = res.flat();
          res = [...new Set(res)];
          this.dataTag = res;
        });
    },

    async addImage() {
      if (this.imagechange == true) {
        let img = await this.convertFileToBase64(document.getElementById("upcover").files[0]);
        var dataup = new FormData();
        dataup.append("file", img);

        const data = await fetch(process.env.VUE_APP_STORAGE_URL+"api/file/upload", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer 16dd3ccc-8c63-42b2-b81b-c30a9c4ce015",
          },
          body: dataup,
        });
        const content = await data.json();
        return content;
      } else {
        return null;
      }
    },

    async addItem() {
      let img = await this.addImage();
      let data = {
        condition: {
          bannerId: this.$store.state.dataBanner.id,
        },
        update: {
          name: this.BannerName,
          image: img == null ? this.$store.state.dataBanner.image : img.data[0].url,
          description: this.description,
          priority: this.Priority,
          startDate: this.startDate,
          endDate: this.endDate,
          isPublic: this.selectPublic,
          isActive: this.selectActive,
          bannerTags: this.bannerTags,
          actionName: this.actionName,
          actionTarget: this.actionTarget,
          actionLink: this.actionLink,
        },
      };
      axios
        .put("/admin-api/banner/edit-banner", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.$toast.show(" Banner Tersimpan ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            duration: 5000,
          });
          this.$router.replace("/banner/list");
        });
    },
    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
        event.target.value = "";
      }
    },
    removeTag(index) {
      this.bannerTags.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.bannerTags.length - 1);
      }
    },
    async selectActionLink() {
      this.actionLink = "";
    },

    selectArticle() {
      this.isModalVisible = true;
      EventBus.emit('open-modal');
    },

    selectPromotion() {
      this.isModalPromotionVisible = true;
      EventBus.emit('open-modal');
    },

    setArticle(item) {
      this.actionLink = item.id;
      this.judulArticle = item.title;
      this.isModalVisible = false;
    },

    setPromotion(item) {
      this.actionLink = item.id;
      this.promotionName = item.name;
      this.isModalPromotionVisible = false;
    }
  },
};
</script>
<style>
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
.ck-editor__editable {
  min-height: 200px;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>
