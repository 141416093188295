<template>
  <Breadcrumbs main="User OTP" title=" User OTP List" />
  <ConfirmationModal
      ref="modalConfirmation"
      :isVisible="isModalConfirmation"
      modalId="processConfirmationModal"
      :title="tittleModal"
      :message="messageModal"
      :isButtonConfirm="isButtonConfirm"
      @confirmed="download"
      @update:isVisible="isModalConfirmation = $event"
    />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <!-- filter and search  -->
                <div class="d-flex justify-content-between mb-0 mt-2">
                  <div class="d-flex">
                    <div class="me-2">
                      <div class="input-group class-form">
                        <input
                          type="search"
                          style="width: 100% !important; height: 45px"
                          placeholder="Pencarian"
                          class="form-control"
                          @keyup.enter="cari()"
                          v-model="filterQuery"
                        />
                      </div>
                    </div>

                    <div class="mx-2 mt-2">
                      <div class="d-flex">
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-success me-3"
                            @click="filter"
                            size="20"
                            type="search"
                          >
                          </vue-feather>
                        </div>
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-primary me-3"
                            @click="reset"
                            size="20"
                            type="x-square"
                          >
                          </vue-feather>
                        </div>
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-info me-2"
                            @click="openModalConfirmation"
                            size="20"
                            type="download"
                          >
                          </vue-feather>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th class="text-center" scope="col">
                    <input
                      type="checkbox"
                      @change="selectAll($event)"
                      :checked="areAllSelected"
                    />
                  </th>
                  <th scope="col">No</th>
                  <th scope="col">Username</th>
                  <th scope="col">OTP</th>
                  <th scope="col">Tipe OTP</th>
                  <th scope="col">Metode OTP</th>
                  <th scope="col">Status OTP</th>
                  <th scope="col">Valid Hingga</th>
                  <th scope="col">HP</th>
                  <th scope="col">Phone Verified</th>
                  <th scope="col">Created At</th>
                </tr>
              </thead>
              <tbody v-show="isLoading == false">
                <tr v-for="item in items" :key="item">
                  <td>
                    <input
                      type="checkbox"
                      :value="item"
                      :checked="isSelected(item)"
                      @change="toggleSelection(item)"
                    />
                  </td>
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.no }}</td>
                  <td>{{ item.username }}</td>
                  <td>{{ item.otp }}</td>
                  <td>{{ item.otp_type }}</td>
                  <td>{{ item.otp_method }}</td>
                  <td>{{ item.otp_status }}</td>
                  <td>{{ item.valid_until }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.is_phone_verified }}</td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
            </table>

            <div class="loader-box" v-show="isLoading == true">
              <div class="loader-2"></div>
            </div>
            <div v-show="isLoading == false && items.length == 0" class="px-4">
              <span>Tidak ada data</span>
            </div>
          </div>

          <!-- row and pagination  -->
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-4 mx-3">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>

              <!-- pagination  -->
              <div class="mt-4 mx-3">
                <vue-awesome-paginate
                  v-show="items.length > 0 && isload == false"
                  :total-items="meta.total"
                  :items-per-page="meta.perPage"
                  :max-pages-shown="meta.perPage"
                  v-model="page"
                  @click="changePage"
                />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select
                      name="basic-1_length"
                      aria-controls="basic-1"
                      class="form-control"
                      @change="showper"
                      v-model="perPage"
                    >
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row and pagination  -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <DeleteBank />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import ConfirmationModal from '@/components/modal_confirmation.vue';
import * as XLSX from "xlsx";

export default {
  components: {
    VueFeather,
    ConfirmationModal
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
      isModalVisible: false,
      isLoadingModal: false,

      selectedItems: [],
      isModalConfirmation: false,
      isButtonConfirm: false,
      tittleModal: "",
      messageModal: ""
    };
  },
  created() {
    
  },
  mounted() {
    this.user = localStorage.getItem("user");
    this.getdata();
  },
  computed: {
    areAllSelected() {
      const currentPageIds = this.items.map(item => item.id);
      return currentPageIds.every(id => this.selectedItems.some(selected => selected.id === id));
    }
  },
  methods: {
    getdata() {
      this.isLoading = true;
      axios
        .get("/admin-api/account/user-otps?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        }).catch((err) => {
          this.reset();
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
    },
    getRow() {
      // var grid = document.getElementById("tabledt");
      // var checkBoxes = grid.getElementsByTagName("INPUT");
      // let data = [];
      // for (var i = 0; i < checkBoxes.length; i++) {
      //   if (checkBoxes[i].checked) {
      //     data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
      //   }
      // }
      // this.selectedData = data;
    },

    filter() {
      this.isLoading = true;
      let params = {
        distributorIds: this.valueDistributor,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/account/user-otps?perPage=" +
            this.perPage +
            "&" +
            valTag[0] +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
          this.isLoading = false;
        }).catch((err) => {
          this.reset();
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
    },

    reset() {
      this.isLoading = true;
      this.valueTag = [];
      this.filterQuery = "";
      this.filterPublik = "";
      this.filterStatus = "";
      this.filter();
      this.filterOpt = false;
      this.isLoading = false;
    },

    cari() {
      this.isLoading = true;
      this.page = 1;
      axios
        .get("/admin-api/account/user-otps?page=1" + "&perPage=" + this.perPage + "&search=" + this.filterQuery, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        }).catch((err) => {
          this.reset();
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
    },

    changePage(x) {
      this.isLoading = true;
      this.page = x;
      axios
        .get(
          "/admin-api/account/user-otps?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        }).catch((err) => {
          this.reset();
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
    },

    showper() {
      this.isLoading = true;
      this.NoPage = 1;
      this.page = 1;
      axios
        .get(
          "/admin-api/account/user-otps?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        }).catch((err) => {
          this.reset();
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
    },

    openModalEdit(item) {
      this.selectedItem = item;
      this.isModalVisible = true;
      EventBus.emit('open-modal', item);
    },

    handleOpenModalLoading(sts) {
      this.isLoadingModal = sts;
    },
    
    handleRefreshData() {
      this.filter();
    },

    openModalConfirmation() {
      if (this.selectedItems.length > 0) {
        this.isModalConfirmation = true;
        this.isButtonConfirm = true;
        this.tittleModal = "Konfirmasi";
        this.messageModal = "Apakah Anda yakin akan melakukan export data ?";
      } else {
        this.$toast.show("Pilih data terlebih dahulu !!!", {
          theme: "outline",
          position: "top-right",
          type: "warning",
          duration: 5000,
        });
      }
    },
    download() {
      const rows = this.selectedItems.map((item, index) => ({
        No: index + 1,
        ID : item.id,
        "Username": item.username,
        "OTP": item.otp,
        "Tipe OTP": item.otp_type,
        "Metode OTP": item.otp_method,
        "Status OTP": item.otp_status,
        "Valid Hingga": item.valid_until,
        "HP": item.phone,
        "Phone Verified": item.is_phone_verified,
        "Created At": item.created_at,
      }));
      
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "List User OTP");
      XLSX.writeFile(workbook, "User OTP Data.xlsx");
    },
    isSelected(item) {
      return this.selectedItems.some(selected => selected.id === item.id);
    },
    toggleSelection(item) {
      const index = this.selectedItems.findIndex(selected => selected.id === item.id);
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    selectAll(event) {
      const currentPageItems = [...this.items];
      if (event.target.checked) {
        // Add items from the current page that are not already in selectedItems
        currentPageItems.forEach(item => {
          if (!this.selectedItems.find(selected => selected.id === item.id)) {
            this.selectedItems.push(item);
          }
        });
      } else {
        // Remove items from the current page that are in selectedItems
        this.selectedItems = this.selectedItems.filter(
          selected => !currentPageItems.some(item => item.id === selected.id)
        );
      }
    },
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
