<template>
  <Breadcrumbs sub="Partner" title="Salesman Customer" />
  <ConfirmationModal
      ref="modalConfirmation"
      :isVisible="isModalConfirmation"
      modalId="processConfirmationModal"
      :title="tittleModal"
      :message="messageModal"
      :isButtonConfirm="isButtonConfirm"
      @confirmed="download"
      @update:isVisible="isModalConfirmation = $event"
    />
  <div class="card">
    <div class="card-body">
      <div class="d-flex gap-5">
        <div class="d-flex align-items-center gap-1">
          <span>Tampilkan</span>
          <select class="form-select" v-model="itemsPerPage" @change="refetch">
            <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
          </select>

          <button type="button" class="btn btn-light" @click="openModalConfirmation">
            <vue-feather size="20" type="download" />
          </button>
        </div>
        <div class="flex-grow-1"></div>
        <div class="d-flex gap-1">
          <input type="search" placeholder="Type to Search" class="form-control" v-model="searchKeyword" />
          <button type="button" class="btn btn-danger" @click="resetFilterQuery">
            <vue-feather size="20" type="x-circle" />
          </button>
        </div>
      </div>

      <div class="table-responsive py-2">
        <table class="table table-striped table-hover table-bordered" id="tabledt">
          <thead>
            <tr>
              <th class="text-center">
                <input
                  type="checkbox"
                  @change="selectAll($event)"
                  :checked="areAllSelected"
                />
              </th>
              <th>No</th>
              <th>Salesman Name</th>
              <th>Customer Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customerSalesman in customerSalesmans" :key="customerSalesman.number">
              <td class="text-center">
                <input
                  type="checkbox"
                  :value="customerSalesman"
                  :checked="isSelected(customerSalesman)"
                  @change="toggleSelection(customerSalesman)"
                />
              </td>
              <td>{{ customerSalesman.number }}</td>
              <td>{{ customerSalesman.salesman.name }}</td>
              <td>{{ customerSalesman.customer.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <span>Menampilkan {{ number_first }} - {{ number_last }} dari {{ items_total }}</span>

        <vue-awesome-paginate
          v-show="customerSalesmans.length"
          v-model="currentPage"
          :total-items="items_total"
          :items-per-page="itemsPerPage"
          :showJumpButtons="true"
          @click="refetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { salesmanService } from "@/_services";
import ConfirmationModal from '@/components/modal_confirmation.vue';
import * as XLSX from "xlsx";

export default {
  components: {
    ConfirmationModal
  },
  data() {
    return {
      customerSalesmans: [],
      customersSalesmanMeta: {},

      pageOptions: [10, 25, 50, 100],
      itemsPerPage: 10,
      currentPage: 1,

      searchKeyword: "",
      filterQuery: {},
      selectedItems: [],
      
      isModalConfirmation: false,
      isButtonConfirm: false,
      tittleModal: "",
      messageModal: ""
    };
  },
  watch: {
    searchKeyword: debounce(function (keyword) {
      this.filterQuery.search = keyword || undefined;
      this.refetch();
    }, 1000),
  },
  computed: {
    number_first() {
      return this.itemsPerPage * (this.currentPage - 1) + 1;
    },
    number_last() {
      return this.number_first - 1 + this.customersSalesmanMeta.count;
    },
    items_total() {
      return this.customersSalesmanMeta.total;
    },
    areAllSelected() {
      const currentPageIds = this.customerSalesmans.map(item => item.id);
      return currentPageIds.every(id => this.selectedItems.some(selected => selected.id === id));
    },
  },
  methods: {
    async refetch() {
      const payload = {
        page: this.currentPage,
        perPage: this.itemsPerPage,
        ...this.filterQuery,
      };
      const { customerSalesmans, meta: customersSalesmanMeta } = await salesmanService.getCustomers(payload);

      this.customerSalesmans = customerSalesmans;
      this.customersSalesmanMeta = customersSalesmanMeta;

      let number = this.number_first;
      this.customerSalesmans.forEach((customerSalesman) => (customerSalesman.number = number++));
    },
    resetFilterQuery() {
      this.filterQuery = {};
      this.refetch();
    },
    openModalConfirmation() {
      if (this.selectedItems.length > 0) {
        this.isModalConfirmation = true;
        this.isButtonConfirm = true;
        this.tittleModal = "Konfirmasi";
        this.messageModal = "Apakah Anda yakin akan melakukan export data ?";
      } else {
        this.$toast.show("Pilih data terlebih dahulu !!!", {
          theme: "outline",
          position: "top-right",
          type: "warning",
          duration: 5000,
        });
      }
    },
    download() {
      const rows = this.selectedItems.map((item, index) => ({
        No: index + 1,
        Nama_Salesman: item.salesman.name,
        Nama_Customer: item.customer.name
      }));
      
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "List Salesman Customer");
      XLSX.writeFile(workbook, "Salesman Customer Data.xlsx");
    },
    isSelected(item) {
      return this.selectedItems.some(selected => selected.id === item.id);
    },
    toggleSelection(item) {
      const index = this.selectedItems.findIndex(selected => selected.id === item.id);
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    selectAll(event) {
      const currentPageItems = [...this.customerSalesmans];
      if (event.target.checked) {
        // Add items from the current page that are not already in selectedItems
        currentPageItems.forEach(item => {
          if (!this.selectedItems.find(selected => selected.id === item.id)) {
            this.selectedItems.push(item);
          }
        });
      } else {
        // Remove items from the current page that are in selectedItems
        this.selectedItems = this.selectedItems.filter(
          selected => !currentPageItems.some(item => item.id === selected.id)
        );
      }
    },
  },
  created() {
    this.refetch();
  },
};
</script>
