<template>
  <div v-if="isVisible" class="modal fade show" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn btn-lg btn-default close" @click="closeModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-sm-8">
                      <!-- filter and search  -->
                      <div class="d-flex justify-content-between mb-0 mt-2">
                        <div class="d-flex">
                          <div class="mx-2">
                            <label class="badge badge-warning" style="font-size: x-small"
                              v-show="tooltipTag && valueTag.length == 0 && textTag">
                              Enter Untuk Menyimpan Tag
                            </label>
                            <div :class="valueTag.length == 0 ? 'card border-0 mt-1' : 'card border-1 mt-1'">
                              <div v-for="(tag, index) in valueTag" class="tag-input__tag" :key="index">
                                {{ tag }}
                                <span @click="removeTag(index)">x</span>
                              </div>
                              <input type="text" list="listArticle" placeholder="Tag Article" class="form-control mx-n4"
                                @change="() => {
                                    this.textTag = true;
                                  }
                                  " @focus="() => {
                              this.tooltipTag = true;
                            }
                            " @focusout="() => {
                              this.tooltipTag = false;
                            }
                            " @keydown.enter="addTag" @keydown.188="addTag" @keydown.delete="removeLastTag" />
                              <datalist id="listArticle">
                                <option v-for="article in dataTag" :key="article" :value="article" />
                              </datalist>
                            </div>
                          </div>

                          <div class="mt-1 me-2">
                            <div class="input-group class-form">
                              <input type="search" style="width: 100% !important" placeholder="Pencarian"
                                class="form-control" @keyup.enter="cari()" v-model="filterQuery" />
                            </div>
                          </div>

                          <div class="mx-2 mt-2">
                            <div class="d-flex">
                              <div>
                                <vue-feather class="btn btn-xs btn-outline-success me-3" @click="filter" size="20"
                                  type="search">
                                </vue-feather>
                              </div>
                              <div>
                                <vue-feather class="btn btn-xs btn-outline-primary me-3" @click="reset" size="20"
                                  type="x-square">
                                </vue-feather>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-hover table-bordered" id="tabledt">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Judul</th>
                        <th scope="col">Dibuat</th>
                        <th scope="col">Status</th>
                        <th scope="col">Publik Status</th>
                        <th scope="col">Tag</th>
                        <th scope="col">Prioritas</th>
                        <th scope="col">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in items" :key="item">
                        <td style="display: none" id="iditem">{{ item.id }}</td>
                        <td>{{ item.no }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.createdAt }}</td>
                        <td>
                          <span :class="item.isActive == true ? 'badge badge-success' : 'badge badge-warning'">
                            {{ item.isActive == true ? "Aktif" : "Tidak Aktif" }}</span>
                        </td>
                        <td>
                          <span :class="item.isPublic == true ? 'badge badge-success' : 'badge badge-warning'">
                            {{ item.isPublic == true ? "Aktif" : "Tidak Aktif" }}</span>
                        </td>
                        <td>
                          <div>
                            <span class="badge badge-success" v-for="(tag, i) in item.articleTags" :key="i">
                              {{ item.articleTags[i].tagName }}
                            </span>
                          </div>
                        </td>
                        <td>{{ item.highlightPriority }}</td>
                        <td>
                          <vue-feather class="btn btn-xs btn-outline-success" size="18" type="save" @click="selectData(item)"></vue-feather>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="loader-box" v-show="isLoading == true">
                    <div class="loader-2"></div>
                  </div>
                  <div v-show="isLoading == false && items.length == 0" class="px-4">
                    <span>Tidak ada data</span>
                  </div>
                </div>

                <!-- row and pagination  -->
                <div class="flex">
                  <div class="d-flex justify-content-end mb-2">
                    <div class="mt-4 mx-3">
                      <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
                    </div>

                    <!-- pagination  -->
                    <div class="mt-4 mx-3">
                      <vue-awesome-paginate v-show="items.length > 0 && isload == false" :total-items="meta.total"
                        :items-per-page="meta.perPage" :max-pages-shown="meta.perPage" v-model="page"
                        @click="changePage" />
                    </div>

                    <div class="mt-n1 mx-4">
                      <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                        <p>
                          <small>Tampilkan</small>
                          <select name="basic-1_length" aria-controls="basic-1" class="form-control" @change="showper"
                            v-model="perPage">
                            <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}
                            </option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end of row and pagination  -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventBus from '@/eventBus.js';

export default {
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
    };
  },
  created() {
    EventBus.on('open-modal', this.handleOpenModal);
  },
  props: {
    title: {
      type: String,
      default: "Confirmation"
    },
    message: {
      type: String,
      default: "Are you sure you want to proceed?"
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit("update:isVisible", false);
    },
    handleOpenModal() {
      this.isModalVisible = true;
      this.getdata();
    },

    getdata() {
      this.isLoading = true;
      axios
        .get("/admin-api/article/list-articles?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articles.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.articles;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        });
    },
    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let data = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    filter() {
      this.items = [];
      this.isLoading = true;
      let params = {
        articleTags: this.valueTag,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/article/list-articles?perPage=" + this.perPage + "&" + valTag[0] + "&search=" + this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articles.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.articles;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
          this.isLoading = false;
        });
    },

    reset() {
      this.filterQuery = "";
      this.valueTag = [];
      this.filterPublik = "";
      this.filterStatus = "";
      this.filter();
      this.filterOpt = false;
    },

    cari() {
      this.items = [];
      this.isLoading = true;
      axios
        .get(
          "/admin-api/article/list-articles?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articles.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.articles;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        });
    },

    changePage(x) {
      this.page = x;
      axios
        .get("/admin-api/article/list-articles?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articles.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.articles;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
        });
    },

    customers(x) {
      this.$store.state.ArticleCustomers = x;
    },

    getTag() {
      axios
        .get("/admin-api/article/list-article-tags", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let data = x.data.data.articleTags;
          let res = data.map((c) => {
            return Object.values(c);
          });
          res = res.flat();
          res = [...new Set(res)];
          this.dataTag = res;
        });
    },

    showper() {
      this.isLoading = true;
      this.isload = true;
      this.NoPage = 1;
      this.page = 1;
      axios
        .get(
          "/admin-api/article/list-articles?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articles.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.articles;
          this.meta = x.data.data.meta;
          this.isload = false;
          this.isLoading = false;
        });
    },

    editRow(x) {
      this.$store.state.dataArticle = x;
    },

    deleteRow(x) {
      this.$store.state.ArticleDeleteId = x.id;
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.valueTag.length >= 1) {
          for (let i = 0; i < this.valueTag.length; i++) {
            if (this.valueTag[i] === val) {
              return false;
            }
          }
        }
        this.valueTag.push(val);
        event.target.value = "";
        this.textTag = false;
      }
    },
    removeTag(index) {
      this.valueTag.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.valueTag.length - 1);
      }
    },
    selectData(item) {
      // console.log(item);
      EventBus.emit('selectArticle', item);
    }
  },
  beforeUnmount() {
    EventBus.off('open-modal', this.handleOpenModal);
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>