<template>
  <button class="btn btn-primary-light" type="button">
    <a @click="logout" class="d-flex"> <vue-feather type="log-out"> </vue-feather>Log out</a>
  </button>
</template>
<script>
//  import firebase from 'firebase';
import UserAuth from "../../auth/js/index";
import axios, { AxiosHeaders } from "axios";
export default {
  components: {},
  methods: {
    logout: function () {
      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {
      //     UserAuth.Logout();
      //     axios
      //       .post("/admin-api/authentication/logout", null, {
      //         headers: {
      //           Accept: "application/json",
      //           Authorization: "Bearer " + this.$store.state.token,
      //         },
      //       })
      //       .then((x) => {
      //         if (x) {
      //           UserAuth.Logout();
      //           localStorage.removeItem("token");
      //           localStorage.removeItem("islogged");
      //           localStorage.removeItem("user");
      //           localStorage.removeItem("loggedIn");
      //           localStorage.removeItem("tokenExpiry");
      //         }
      //       });
      //     this.$router.replace("/auth/login");
      //   });
      axios
        .post("/admin-api/authentication/logout", null, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((x) => {
          if (x) {
            UserAuth.Logout();
            localStorage.removeItem("token");
            localStorage.removeItem("islogged");
            localStorage.removeItem("user");
            localStorage.removeItem("loggedIn");
            localStorage.removeItem("tokenExpiry");
          } else {
            UserAuth.Logout();
            localStorage.removeItem("token");
            localStorage.removeItem("islogged");
            localStorage.removeItem("user");
            localStorage.removeItem("loggedIn");
            localStorage.removeItem("tokenExpiry")
          }
        }).catch((err) => {
          UserAuth.Logout();
          localStorage.removeItem("token");
          localStorage.removeItem("islogged");
          localStorage.removeItem("user");
          localStorage.removeItem("loggedIn");
          localStorage.removeItem("tokenExpiry")
        });
      this.$router.replace("/auth/login");
    },
  },
};
</script>
